import { useEffect, useState } from "react";

import {
  Typography,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Box,
} from "@mui/material";
import {
  BelegbareKurseResponse,
  BelegbarerKurs,
} from "../../../../models/admin-management-entities";

export const AdminManagementKursSelector: React.FC<{
  belegbareKurse: BelegbareKurseResponse;
  setChangeBelegungKursSelection: (_: number) => void;
  changeBelegungKursIdSelection: number;
  setSelectedMitgliedschaftsbeginn?: (_: Date) => void;
  selectedMitgliedschaftsbeginn: Date;
  onSubmitCheck?: boolean;
  isRequired?: boolean;
  isCourseChange?: boolean;
}> = ({
  belegbareKurse,
  setChangeBelegungKursSelection,
  changeBelegungKursIdSelection,
  setSelectedMitgliedschaftsbeginn,
  selectedMitgliedschaftsbeginn,
  onSubmitCheck,
  isRequired,
  isCourseChange = false,
}) => {
  const [borderColor, setBorderColor] = useState("var(--green)");
  const [selectedValue, setSelectedValue] = useState<number>();

  useEffect(() => {
    if (isRequired && onSubmitCheck && !selectedValue) {
      setBorderColor("var(--red)");
    } else if (changeBelegungKursIdSelection === undefined) {
      setBorderColor("var(--green)");
    }
  }, [onSubmitCheck, isRequired, selectedValue, changeBelegungKursIdSelection]);

  const selectorStyle = {
    height: "70px",
    fontSize: "var(--h1)",
    "& .MuiSvgIcon-root": {
      color: "var(--orange)",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: borderColor,
      borderWidth: "2px",
    },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: borderColor,
      borderWidth: "2px",
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: borderColor,
      borderWidth: "2px",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "1rem",
      }}
    >
      <FormControl sx={{ display: "flex", justifyContent: "center" }}>
        <InputLabel htmlFor="groupedselect">
          Verfügbare Sportgruppen*
        </InputLabel>
        <Select
          value={
            changeBelegungKursIdSelection
              ? String(changeBelegungKursIdSelection)
              : ""
          }
          id="groupedselect"
          label="Verfügbare Sportgruppen"
          onChange={(event: SelectChangeEvent) => {
            setSelectedValue(Number(event.target.value));
            setChangeBelegungKursSelection(Number(event.target.value));
            setBorderColor("var(--orange)");
          }}
          sx={selectorStyle}
        >
          <ListSubheader>Verfügbare Sportgruppen</ListSubheader>
          {belegbareKurse &&
            belegbareKurse.verfugbareKurse
              .sort((a, b) => a.KursName.localeCompare(b.KursName))
              .map((bk: BelegbarerKurs) => {
                return (
                  <MenuItem key={bk.ID} value={bk.ID}>
                    {bk.KursName}
                  </MenuItem>
                );
              })}
          <ListSubheader>Ausgelastete Sportgruppen</ListSubheader>
          {belegbareKurse &&
            belegbareKurse.volleKurse
              .sort((a, b) => a.KursName.localeCompare(b.KursName))
              .map((bk: BelegbarerKurs) => {
                return (
                  <MenuItem
                    color="rgba(255, 0, 0, 0.5);"
                    key={bk.ID}
                    value={bk.ID}
                  >
                    <Typography color={"rgba(255, 0, 0, 0.5);"}>
                      {bk.KursName}
                    </Typography>
                  </MenuItem>
                );
              })}
        </Select>
      </FormControl>
      {!isCourseChange && (
        <FormControl sx={{ display: "flex", justifyContent: "center" }}>
          <InputLabel>Mitgliedschaftsbeginn</InputLabel>
          <Select
            value={selectedMitgliedschaftsbeginn.toISOString()}
            label="Mitgliedschaftsbeginn"
            onChange={(event: SelectChangeEvent) => {
              setSelectedMitgliedschaftsbeginn(new Date(event.target.value));
            }}
            sx={selectorStyle}
          >
            {[0, 1, 2].map((i: number) => {
              const currentDate = new Date();
              const month = currentDate.getMonth() + i;
              const year = currentDate.getFullYear() + Math.floor(month / 12);
              const normalizedMonth = month % 12;

              const dateForMenuItem = new Date(
                Date.UTC(year, normalizedMonth, 1)
              );

              return (
                <MenuItem
                  value={dateForMenuItem.toISOString()}
                  key={normalizedMonth + 1}
                >
                  {`01.${(normalizedMonth + 1)
                    .toString()
                    .padStart(2, "0")}.${year}`}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

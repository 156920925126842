import React, { useContext, useEffect, useState } from "react";
import {
  KursBelegung,
  Mitglied,
  BelegbareKurseResponse,
  InputData,
  AdminManagementDialogKey,
} from "../../../models/admin-management-entities";
import {
  AdminManagementDialogTypes,
  AdminManagementDialog,
} from "./AdminManagementDialog";
import {
  isEmail,
  isNotEmpty,
  isValidPLZ,
  validateDate,
} from "../../../util/RegexFunctions";
import {
  dateToString,
  formatDateWithLeadingZeros,
} from "../../../util/DateFormatHelper";
import {
  Box,
  FormControl,
  InputLabel,
  List,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  addBelegung,
  addKunde,
  checkIban,
  deleteBelegung,
  updateBelegung,
  updateKunde,
} from "../../../api/admin-management-api";
import { HttpStatusCode } from "axios";
import { SnackBarContext } from "../../../context/snackbar-context";
import AdminManagementListHeader from "../AdminManagementListHeader";
import InfiniteScroll from "react-infinite-scroll-component";
import AdminManagementListItem from "../AdminManagementListItem";
import AdminInfoDialog from "../../AdminTrainerApp/Dialogs/AdminInfoDialog";
import trash from "../../../img/trash.svg";
import edit from "../../../img/editPenSVG.svg";
import {
  AddBelegungDTO,
  UpdateBelegungDTO,
} from "../../../api/dtos/admin-management-dtos";
import { AdminManagementKursSelector } from "./InputFields/AdminManagementKursSelector";

export interface BelegungToChangeObject {
  ID_Belegung: number;
  Kursname: string;
}

export const AdminManagementMitgliedDialog: React.FC<{
  mitglied: Mitglied;
  setMitglied?: (_: Mitglied) => void;
  isOpen: boolean;
  closeDialog: () => void;
  belegbareKurse?: BelegbareKurseResponse;
  isAddingNewItem?: boolean;
  setIsAddingNewItem?: (_: boolean) => void;
  reloadData: () => Promise<void>;
  reloadMitglied: (idKunde: number) => Promise<void>;
}> = ({
  mitglied,
  setMitglied,
  isOpen,
  closeDialog,
  isAddingNewItem = false,
  setIsAddingNewItem,
  reloadData,
  belegbareKurse,
  reloadMitglied,
}) => {
  const [currentMitglied, setCurrentMitglied] = useState<Mitglied>(undefined);
  const [alleBelegungen, setAlleBelegungen] =
    useState<KursBelegung[]>(undefined);
  const [belegungenAktivRecord, setBelegungenAktivRecord] = useState<{
    [key: number]: boolean;
  }>({});

  const snackBarCtx = useContext(SnackBarContext);

  /* Update and Delete Dialog */
  const [isDeleteBelegungDialogOpen, setIsDeleteBelegungDialogOpen] =
    useState<boolean>(false);
  const [idBelegungToDelete, setIdBelegungToDelete] =
    useState<number>(undefined);
  const [isChangeDialogOpen, setIsChangeDialogOpen] = useState<boolean>(false);
  const [isAddBelegungDialogOpen, setIsAddBelegungDialogOpen] =
    useState<boolean>(false);
  const [belegungToChange, setBelegungToChange] =
    useState<BelegungToChangeObject>(undefined);

  const [changeBelegungKursIdSelection, setChangeBelegungKursIdSelection] =
    useState<number>(undefined);

  const mitgliedschaftsbeginnCurrentMonth = new Date(
    Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1)
  );

  const [selectedMitgliedschaftsende, setSelectedMitgliedschaftsende] =
    useState<Date | null>(null);

  const [selectedMitgliedschaftsbeginn, setSelectedMitgliedschaftsbeginn] =
    useState<Date>(mitgliedschaftsbeginnCurrentMonth);

  function getLastDayOfMonth(year: number, month: number) {
    return new Date(year, month, 0);
  }

  useEffect(() => {
    if (mitglied) {
      setCurrentMitglied(mitglied);
      if (mitglied.Belegungen) {
        setAlleBelegungen(mitglied.Belegungen);
      }
    }
  }, [mitglied]);

  const currentDate = new Date();
  const lastDayOfCurrentMonth = getLastDayOfMonth(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1
  );

  useEffect(() => {
    if (isOpen && !selectedMitgliedschaftsende) {
      setSelectedMitgliedschaftsende(lastDayOfCurrentMonth);
    }
  }, [isOpen, selectedMitgliedschaftsende, lastDayOfCurrentMonth]);

  useEffect(() => {
    const newBelegungenAktivRecord: { [key: number]: boolean } = {};
    if (alleBelegungen) {
      alleBelegungen.forEach((kursBelegung: KursBelegung) => {
        let isCurrentBelegungAktiv: boolean = false;
        if (kursBelegung.IsAud === true) {
          isCurrentBelegungAktiv = false;
        } else if (
          kursBelegung.Mitgliedschaftsende === null ||
          new Date(kursBelegung.Mitgliedschaftsende) > new Date()
        ) {
          isCurrentBelegungAktiv = true;
        }
        newBelegungenAktivRecord[kursBelegung.ID_Belegung] =
          isCurrentBelegungAktiv;
      });
      setBelegungenAktivRecord(newBelegungenAktivRecord);
    }
  }, [alleBelegungen]);

  /* eslint-disable */
  useEffect(() => {
    if (!(isChangeDialogOpen || isAddBelegungDialogOpen)) {
      setBelegungToChange(undefined);
      setChangeBelegungKursIdSelection(undefined);
      setSelectedMitgliedschaftsbeginn(mitgliedschaftsbeginnCurrentMonth);
    }
  }, [isChangeDialogOpen, isAddBelegungDialogOpen]);

  /* eslint-disable */
  useEffect(() => {
    if (!isDeleteBelegungDialogOpen) {
      setIdBelegungToDelete(undefined);
      setSelectedMitgliedschaftsende(lastDayOfCurrentMonth);
    }
  }, [isDeleteBelegungDialogOpen]);

  if (!mitglied) {
    return null;
  }

  const isValidIBAN = async (iban: string, isRequired: boolean) => {
    if (iban === undefined || iban.trim() === "") return !isRequired;
    const isValid = await checkIban(iban);
    return isValid;
  };

  const mitgliedInputData: InputData = {
    Personendaten: {
      "Vorname*": {
        attribute: "Vorname",
        data: mitglied.Vorname,
        type: AdminManagementDialogTypes.TEXT_INPUT,
        validatorFct: isNotEmpty,
        isRequired: true,
      },
      "Nachname*": {
        attribute: "Nachname",
        data: mitglied.Nachname,
        type: AdminManagementDialogTypes.TEXT_INPUT,
        validatorFct: isNotEmpty,
        isRequired: true,
      },
      Geschlecht: {
        attribute: "Geschlecht",
        data: mitglied.Geschlecht,
        type: AdminManagementDialogTypes.TEXT_INPUT,
      },
      Geburtsdatum: {
        attribute: "Geburtsdatum",
        data: dateToString(mitglied.Geburtsdatum),
        type: AdminManagementDialogTypes.DATE_INPUT,
        validatorFct: validateDate,
      },
    },
    Adressdaten: {
      Strasse: {
        attribute: "Strasse",
        data: mitglied.Strasse,
        type: AdminManagementDialogTypes.TEXT_INPUT,
      },
      PLZ: {
        attribute: "PLZ",
        data: mitglied.PLZ,
        type: AdminManagementDialogTypes.TEXT_INPUT,
        validatorFct: isValidPLZ,
      },
      Stadt: {
        attribute: "Stadt",
        data: mitglied.Stadt,
        type: AdminManagementDialogTypes.TEXT_INPUT,
      },
    },
    Kontaktdaten: {
      Telefonnummer: {
        attribute: "Telefonnummer",
        data: mitglied.Telefonnummer,
        type: AdminManagementDialogTypes.TEXT_INPUT,
      },
      "E-Mail Adresse": {
        attribute: "Emailadresse",
        data: mitglied.Emailadresse,
        type: AdminManagementDialogTypes.TEXT_INPUT,
        validatorFct: isEmail,
      },
    },
    Kontodaten: {
      "Kontoinhaber:in*": {
        attribute: "Kontoinhaber",
        data: mitglied.Kontoinhaber,
        type: AdminManagementDialogTypes.TEXT_INPUT,
        validatorFct: isNotEmpty,
        isRequired: true,
      },
      "Kontonummer*": {
        attribute: "IBAN",
        data: mitglied.IBAN,
        type: AdminManagementDialogTypes.TEXT_INPUT,
        validatorFct: isValidIBAN,
        isRequired: true,
      },
    },
  };

  if (isAddingNewItem === true) {
    mitgliedInputData["Belegungen"] = {
      "Belegung hinzufügen*": {
        attribute: "Belegung",
        data: "",
        type: AdminManagementDialogTypes.KURS_SELECTOR,
        isRequired: true,
        validatorFct: isNotEmpty,
      },
    };
  }

  if (isAddingNewItem === false) {
    mitgliedInputData["Mitgliedschaft"] = {
      Hinzugefügt: {
        attribute: "Hinzugefuegt",
        data: dateToString(mitglied.Hinzugefuegt),
        type: AdminManagementDialogTypes.DISABLED,
      },
      "Beginn Mitgliedschaft": {
        attribute: "Mitgliedschaftsbeginn",
        data: dateToString(mitglied.Mitgliedschaftsbeginn),
        type: AdminManagementDialogTypes.DISABLED,
      },
    };
  }

  const addMitgliedInputData = Object.keys(mitgliedInputData).reduce(
    (acc, key) => {
      acc[key] = mitgliedInputData[key];
      return acc;
    },
    {} as typeof mitgliedInputData
  );

  function formatDataForCreateRequest(mitglied: Mitglied) {
    const {
      Mitgliedschaftsbeginn,
      Mitgliedschaftsende,
      Belegungen,
      Hinzugefuegt,
      ...otherProps
    } = mitglied;

    return {
      Hinzugefuegt: new Date(),
      ...otherProps,
    };
  }

  function formatDataForUpdateRequest(mitglied: Mitglied) {
    const {
      ID,
      Mitgliedschaftsbeginn,
      Mitgliedschaftsende,
      Belegungen,
      Hinzugefuegt,
      ...otherProps
    } = mitglied;

    return {
      ID_Kunde: Number(ID),
      ...otherProps,
    };
  }

  const getMitgliedschaftsendeSelector = () => {
    return (
      <FormControl sx={{ display: "flex", justifyContent: "center" }}>
        <InputLabel>Mitgliedschaftsende</InputLabel>
        <Select
          value={selectedMitgliedschaftsende.toISOString()}
          label="Mitgliedschaftsende"
          onChange={(event: SelectChangeEvent) => {
            const newDate = new Date(event.target.value);
            setSelectedMitgliedschaftsende(newDate);
          }}
          sx={{
            height: "70px",
            fontSize: "var(--h1)",
            "& .MuiSvgIcon-root": {
              color: "var(--orange)",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "var(--green)",
              borderWidth: "2px",
            },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "var(--green)",
              borderWidth: "2px",
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "var(--green)",
                borderWidth: "2px",
              },
          }}
        >
          {/*generates a dropdown list with the following options:
        the last day of the current month, the last day of the next month,
          and the last day of another month. */}
          {[0, 1, 2].map((i: number) => {
            const currentDate = new Date();
            const month = currentDate.getMonth() + i;
            const year = currentDate.getFullYear() + Math.floor(month / 12);
            const normalizedMonth = month % 12;
            const lastDayDate = getLastDayOfMonth(year, normalizedMonth + 1);

            return (
              <MenuItem
                value={lastDayDate.toISOString()}
                key={lastDayDate.toISOString()}
              >
                {formatDateWithLeadingZeros(lastDayDate)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  return (
    <>
      <AdminManagementDialog
        selectedMitgliedschaftsbeginn={selectedMitgliedschaftsbeginn}
        dialogKey={AdminManagementDialogKey.MITGLIED}
        title={
          currentMitglied
            ? `${currentMitglied.Vorname} ${currentMitglied.Nachname}`
            : ""
        }
        isOpen={isOpen}
        closeDialog={closeDialog}
        entity={currentMitglied}
        setEntity={setCurrentMitglied}
        allBelegbareKurse={belegbareKurse}
        inputData={isAddingNewItem ? addMitgliedInputData : mitgliedInputData}
        onClickBelegungen={setIsAddBelegungDialogOpen}
        setSelectedMitgliedschaftsbeginn={setSelectedMitgliedschaftsbeginn}
        addFnct={
          isAddingNewItem
            ? async (idSelectedKurs: number) => {
                await addKunde(formatDataForCreateRequest(currentMitglied))
                  .then(async (response) => {
                    if (response.status === HttpStatusCode.Created) {
                      setMitglied(response);
                      setCurrentMitglied(response);
                      setIsAddingNewItem(false);
                      await addBelegung({
                        ID_Kurs: idSelectedKurs,
                        ID_Kunde: response.ID,
                        Mitgliedschaftsbeginn: selectedMitgliedschaftsbeginn,
                      });
                      reloadMitglied(response.ID);
                      reloadData();
                      setSelectedMitgliedschaftsbeginn(
                        mitgliedschaftsbeginnCurrentMonth
                      );
                      snackBarCtx.openSnackbar(
                        "Mitglied wurde erfolgreich hinzugefügt!",
                        "success"
                      );
                    }
                  })
                  .catch((response) => {
                    snackBarCtx.openSnackbar(
                      "Mitglied kann nicht hinzugefügt werden: " +
                        response.message,
                      "error"
                    );
                  });
              }
            : undefined
        }
        updateFnct={async (mitglied: Mitglied) => {
          await updateKunde(formatDataForUpdateRequest(mitglied))
            .then((response) => {
              if (response.status === HttpStatusCode.Ok) {
                reloadData();
                snackBarCtx.openSnackbar(
                  "Mitglied wurde erfolgreich geändert!",
                  "success"
                );
              }
            })
            .catch((response) => {
              snackBarCtx.openSnackbar(
                "Mitglied kann nicht geändert werden: " + response.message,
                "error"
              );
            });
        }}
        entityId={mitglied.ID}
        initialData={mitglied}
        children={
          !isAddingNewItem && (
            <>
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#607783",
                      fontSize: "12px",
                      padding: "16px 0 0 16px",
                      fontWeight: "900",
                    }}
                  >
                    Belegungen
                  </Typography>
                </Box>
              </>
              <Box sx={{ width: "100%" }}>
                <>
                  <AdminManagementListHeader
                    isWithDistance={true}
                    columns={[
                      { title: "ID_Belegung", flex: 5 },
                      { title: "Kursname", flex: 5 },
                      { title: "Kurzbezeichnung", flex: 5 },
                      { title: "Mitgliedschaftsbeginn", flex: 5 },
                      { title: "Mitgliedschaftsende", flex: 5 },
                      { title: "Status", flex: 5 },
                      { title: "", flex: 1 },
                    ]}
                  ></AdminManagementListHeader>
                  <List
                    sx={{
                      width: "100%",
                      margin: "auto",
                      padding: "0",
                    }}
                  >
                    <InfiniteScroll
                      dataLength={alleBelegungen ? alleBelegungen.length : 0}
                      next={() => {}}
                      hasMore={false}
                      loader={<></>}
                    >
                      {alleBelegungen &&
                        alleBelegungen
                          .sort((a, b) => {
                            const isAktivA =
                              belegungenAktivRecord[a.ID_Belegung] === true;
                            const isAktivB =
                              belegungenAktivRecord[b.ID_Belegung] === true;

                            if (isAktivA && !isAktivB) {
                              return -1;
                            }
                            if (!isAktivA && isAktivB) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((item: KursBelegung, idx) => (
                            <AdminManagementListItem
                              showBelegungenContextMenu={true}
                              dialogKey={AdminManagementDialogKey.BELEGUNG}
                              key={idx}
                              data={item}
                              columns={[
                                { attrName: "ID_Belegung", flex: 5 },
                                { attrName: "Kursname", flex: 5 },
                                { attrName: "Kurzbezeichnung", flex: 5 },
                                {
                                  attrName: "Mitgliedschaftsbeginn",
                                  flex: 5,
                                  processFnct: (_: Date) => {
                                    return dateToString(_);
                                  },
                                },
                                {
                                  attrName: "Mitgliedschaftsende",
                                  flex: 5,
                                  processFnct: (_: Date) => {
                                    return dateToString(_);
                                  },
                                },
                                {
                                  attrName: "IsAktiv",
                                  flex: 5,
                                  processFnct: () => {
                                    if (
                                      belegungenAktivRecord[
                                        item.ID_Belegung
                                      ] === true
                                    ) {
                                      return "aktiv";
                                    }
                                    return "inaktiv";
                                  },
                                },
                              ]}
                              onClickFct={(id: number) => {
                                // TODO: Check if we even need this
                              }}
                              onClickChangeBelegung={(
                                ID_Belegung: number,
                                Kursname: string
                              ) => {
                                setBelegungToChange({
                                  ID_Belegung,
                                  Kursname,
                                });
                                setIsChangeDialogOpen(true);
                              }}
                              onClickDeleteBelegung={(ID_Belegung: number) => {
                                setIdBelegungToDelete(ID_Belegung);
                                setIsDeleteBelegungDialogOpen(true);
                              }}
                            ></AdminManagementListItem>
                          ))}
                    </InfiniteScroll>
                  </List>
                  {alleBelegungen && alleBelegungen.length === 0 && (
                    <Typography
                      sx={{
                        marginTop: "50px",
                        textAlign: "center",
                        fontSize: "var(--h1)",
                        color: "var(--grey)",
                      }}
                    >
                      {"Keine Belegungen gefunden"}
                    </Typography>
                  )}
                </>
              </Box>
            </>
          )
        }
      ></AdminManagementDialog>
      {/* Change Dialog */}
      <AdminInfoDialog
        isOpen={isChangeDialogOpen}
        onCloseDialog={() => {
          setIsChangeDialogOpen(false);
        }}
        iconURL={edit}
        textChildren={
          <>
            {"Möchtest du die Sportgruppe mit den folgenden Daten wechseln?"}
            {belegungToChange && (
              <b>
                <br />
                {belegungToChange.ID_Belegung &&
                  `ID: ${belegungToChange.ID_Belegung}`}
                <br />
                {belegungToChange.Kursname &&
                  `Kurs: ${belegungToChange.Kursname}`}
              </b>
            )}
          </>
        }
        children={
          <AdminManagementKursSelector
            isCourseChange={true}
            belegbareKurse={belegbareKurse}
            setChangeBelegungKursSelection={setChangeBelegungKursIdSelection}
            setSelectedMitgliedschaftsbeginn={setSelectedMitgliedschaftsbeginn}
            selectedMitgliedschaftsbeginn={selectedMitgliedschaftsbeginn}
            changeBelegungKursIdSelection={changeBelegungKursIdSelection}
          />
        }
        rightBtnLabel="Ändern"
        rightBtnOnClick={async () => {
          const inputObject: UpdateBelegungDTO = {
            ID_Belegung: belegungToChange.ID_Belegung,
            ID_Kunde: mitglied.ID,
            ID_Kurs: changeBelegungKursIdSelection,
            Mitgliedschaftsbeginn: selectedMitgliedschaftsbeginn,
          };
          await updateBelegung(inputObject)
            .then(async (response: { status: HttpStatusCode }) => {
              if (response.status === HttpStatusCode.Ok) {
                snackBarCtx.openSnackbar(
                  "Sportgruppe wurde erfolgreich gewechselt!",
                  "success"
                );
                setIsChangeDialogOpen(false);
                await reloadMitglied(mitglied.ID);
                await reloadData();
              }
            })
            .catch((response: { message: string }) => {
              snackBarCtx.openSnackbar(
                "Sportgruppe kann nicht gewechselt werden: " + response.message,
                "error"
              );
            });
        }}
        leftBtnLabel="Abbrechen"
        leftBtnOnClick={() => {
          setIsChangeDialogOpen(false);
        }}
      ></AdminInfoDialog>
      {/* Add Belegung Dialog */}
      <AdminInfoDialog
        isOpen={isAddBelegungDialogOpen}
        onCloseDialog={() => {
          setIsAddBelegungDialogOpen(false);
        }}
        iconURL={edit}
        textChildren={
          <>
            <>{`Möchtest du noch eine zusätzliche Sportgruppe buchen?`}</>
          </>
        }
        children={
          <AdminManagementKursSelector
            selectedMitgliedschaftsbeginn={selectedMitgliedschaftsbeginn}
            setSelectedMitgliedschaftsbeginn={setSelectedMitgliedschaftsbeginn}
            belegbareKurse={belegbareKurse}
            setChangeBelegungKursSelection={setChangeBelegungKursIdSelection}
            changeBelegungKursIdSelection={changeBelegungKursIdSelection}
          />
        }
        rightBtnLabel="Speichern"
        rightBtnOnClick={async () => {
          const inputObject: AddBelegungDTO = {
            ID_Kunde: mitglied.ID,
            ID_Kurs: changeBelegungKursIdSelection,
            Mitgliedschaftsbeginn: selectedMitgliedschaftsbeginn,
          };
          await addBelegung(inputObject)
            .then(async (response: { status: HttpStatusCode }) => {
              if (response.status === HttpStatusCode.Created) {
                snackBarCtx.openSnackbar(
                  "Belegung wurde erfolgreich hinzugefügt!",
                  "success"
                );
                setIsAddBelegungDialogOpen(false);
                await reloadMitglied(mitglied.ID);
                await reloadData();
              }
            })
            .catch((response: { message: string }) => {
              snackBarCtx.openSnackbar(
                "Belegung kann nicht hinzugefügt werden: " + response.message,
                "error"
              );
            });
        }}
        leftBtnLabel="Abbrechen"
        leftBtnOnClick={() => {
          setIsAddBelegungDialogOpen(false);
        }}
      ></AdminInfoDialog>
      {/* Delete Dialog */}
      <AdminInfoDialog
        isOpen={isDeleteBelegungDialogOpen}
        onCloseDialog={() => {
          setIsDeleteBelegungDialogOpen(false);
        }}
        iconURL={trash}
        textChildren={
          <>
            {`Möchtest du die Belegung mit den folgenden Daten beenden?`}
            {idBelegungToDelete && (
              <b>
                <br></br>
                {`ID: ${idBelegungToDelete}`}
              </b>
            )}
          </>
        }
        children={getMitgliedschaftsendeSelector()}
        rightBtnLabel="Bestätigen"
        rightBtnOnClick={async () => {
          await deleteBelegung({
            ID_Belegung: idBelegungToDelete,
            Mitgliedschaftsende: selectedMitgliedschaftsende,
          })
            .then(async (response: { status: HttpStatusCode }) => {
              if (response.status === HttpStatusCode.Ok) {
                snackBarCtx.openSnackbar(
                  "Belegung wurde erfolgreich beendet!",
                  "success"
                );
                setIsDeleteBelegungDialogOpen(false);
                await reloadMitglied(mitglied.ID);
                await reloadData();
              }
            })
            .catch((response: { message: string }) => {
              snackBarCtx.openSnackbar(
                "Belegung kann nicht beenden werden: " + response.message,
                "error"
              );
            });
        }}
        leftBtnLabel="Abbrechen"
        leftBtnOnClick={() => {
          setIsDeleteBelegungDialogOpen(false);
        }}
      ></AdminInfoDialog>
    </>
  );
};
